<template>
  <label
    :class="`v-arora-input v-arora-input--${appConfig.VueSettingsPreRun.InputLayout}`"
    v-form-validator="{
      Disabled: disabled,
      Form: validatorForm,
      Value: phoneInput.replace(/\D/g, ''),
      Required: required,
      Validations: ['phone'],
      MinLength: phoneLength,
      MaxLength: phoneLength
    }"
  >
    <input
      :id="uid"
      :aria-label="label.toString()"
      :aria-valuemax="phoneLength"
      :aria-valuemin="phoneLength"
      :class="{ disabled: disabled }"
      :data-test-id="dataTestId"
      :disabled="disabled"
      :inert="disabled"
      :maxlength="phoneMask.length"
      autocomplete="tel"
      placeholder="&nbsp;"
      type="tel"
      v-maska="options"
      v-model="phoneInput"
      @keyup.enter="onEnterProxy()"
    />
    <span
      :class="[
        $slots['leftEnd']
          ? `v-arora-input--${appConfig.VueSettingsPreRun.InputLayout}__label v-arora-input--${appConfig.VueSettingsPreRun.InputLayout}__label--has-left-end`
          : `v-arora-input--${appConfig.VueSettingsPreRun.InputLayout}__label`
      ]"
    >
      <span>
        {{ label }}
        <span
          v-if="required"
          class="v-required"
        />
      </span>
    </span>

    <span
      :class="`v-arora-input--${appConfig.VueSettingsPreRun.InputLayout}__right-end`"
    >
      <slot name="rightEnd" />
    </span>
    <span
      v-if="$slots['leftEnd']"
      :class="`v-arora-input--${appConfig.VueSettingsPreRun.InputLayout}__left-end`"
    >
      <slot name="leftEnd" />
    </span>
  </label>
</template>

<script setup lang="ts">
import type { MaskaDetail } from 'maska'
import type { HasValidatorForm } from '~types/props'

import { ISO, vFormValidator, type VInputType } from '@arora/common'

import { vMaska } from 'maska/vue'

const {
  disabled = false,
  focusOnMount,
  onEnter = () => {
    /* do nothing by default */
  },
  phone = null,
  required = false
} = defineProps<
  VInputType &
    HasValidatorForm & {
      phone?: string | null
      onEnter?: () => void
    }
>()
const emit = defineEmits(['update:phone'])

const { phoneLength, phoneMask } = usePhoneNumber()
const appConfig = useAppConfig()

const uid = useId()

const phoneInput = ref<string>('')

const options = reactive({
  mask: phoneMask.value,
  onMaska: (detail: MaskaDetail) => {
    if (detail.completed) {
      const maskValue = phoneMask.value.replaceAll(/\D/g, '')
      emit('update:phone', maskValue + detail.unmasked)
    }
  },
  preProcess: (value: string) => {
    if (appConfig.VueSettingsPreRun.PhoneCountry === ISO.RussianFederation)
      return value.startsWith('8') ? `7${value.slice(1)}` : value

    return value
  }
})

onMounted(() => {
  if (phone) phoneInput.value = phone
  if (focusOnMount) document.querySelector<HTMLInputElement>(`#${uid}`)?.focus()
})

watch(
  () => phone,
  (newValue, oldValue) => {
    if (newValue && oldValue !== newValue && newValue !== phoneInput.value)
      phoneInput.value = newValue
  }
)

function onEnterProxy(): void {
  if (onEnter && phone?.replace(/\D/g, '').length === phoneMask.value.length)
    onEnter()
}
</script>
